.root {
  position: relative;
  margin: 0 auto;
  width: calc(100% - 152px);
  height: 48px;
}

.container {
  position: relative;
}

.activeHandle {
  z-index: 1;
}

.leftValue,
.rightValue {
  align-items: center;
  border: 1px solid var(--colorDarkBeige);
  border-radius: 0.5rem;
  display: flex;
  height: 46px;
  justify-content: center;
  padding: 15px 0;
  position: absolute;
  top: 3px;
  width: 46px;
}

.leftValue {
  left: -72px;
}

.rightValue {
  right: -72px;
}
