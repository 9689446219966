@import '../../styles/customMediaQueries.css';

.root {
  display: inline-block;
  width: 100%;

  @media (--viewportLarge) {
    width: unset;
  }
}
