@import '../../styles/customMediaQueries.css';

.root {
  @media (--viewportLarge) {
    display: flex;
  }

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.coverEverything {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.imageContainer {
  aspect-ratio: var(--listingPreviewAspectRatioMobile);
  display: block;
  position: relative;
  width: 100%;

  @media (--viewportLarge) {
    flex: 2;
  }
}

.virtual {
  background: var(--colorAlertBlue);
  border-radius: 1rem 0 1rem 0;
  left: 0;
  padding: 18px;
  position: absolute;
  top: 0;
}

.virtual svg {
  color: var(--colorWhite);
  fill: none;
}

.imageOverlay {
  align-items: center;
  background: var(--colorForeground);
  border-radius: 0.5rem;
  bottom: 1rem;
  color: var(--colorWhite);
  display: flex;
  height: 38px;
  justify-content: center;
  left: 0;
  margin: 0 6px;
  padding: 7px 0;
  position: absolute;
  right: 0;
  text-wrap: nowrap;
}

.reviewRating {
  align-items: center;
  display: flex;
}

.divider {
  border-left: 2px solid var(--colorDarkBeige);
  display: block;
  height: 100%;
  margin: 0 12px;
}

.rootForImage {
  composes: coverEverything;

  border-radius: 1rem 1rem 0 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  object-fit: cover;

  @media (--viewportLarge) {
    border-radius: var(--borderRadiusLarge);
  }
}

.timer {
  fill: none;
  margin-right: 6px;
}

.info {
  background: var(--colorLightBeige);
  border-radius: 0 0 1rem 1rem;
  color: var(--colorForeground);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 14px 0 0 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: var(--borderRadiusLarge);
    flex: 3;
    margin-left: 6px;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  font-size: 22px;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
  margin: 30px 18px 0;

  @media (--viewportLarge) {
    margin: 30px 48px 0;
  }
}

.description {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
  margin: 18px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (--viewportLarge) {
    margin: 18px 48px;
  }
}

.location {
  display: grid;
  grid-template-columns: 24px 1fr;
  margin: 0 18px;

  @media (--viewportLarge) {
    margin: 18px 48px;
  }
}

.location svg {
  fill: none;
  height: 24px;
  margin-right: 6px;
  width: 24px;
}

.location span {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 26px;
  margin-left: 6px;
}

.moreInfo {
  align-items: center;
  background: var(--colorDarkBeige);
  border-radius: 0 0 1rem 1rem;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding: 18px;

  @media (--viewportLarge) {
    padding: 18px 48px;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
}

.priceValue {
  /* Font */
  font-size: 18px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 26px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.headerLink {
  composes: buttonPrimaryInline from global;

  align-items: center;
  border-radius: 0.5rem;
  display: flex;
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  line-height: 16px;
  padding: 15px 20px;
  width: fit-content;
}

.headerLink svg {
  fill: none;
  margin-left: 10px;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
