@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  display: block;
  margin: 0;
}

.twoColumns {
  grid-template-columns: 1fr 1fr;

  @media (--viewportMedium) {
    display: grid;
  }
}

.item {
  align-items: center;
  background: var(--colorLightBeige);
  border-radius: 0.5rem;
  display: flex;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  justify-content: center;
  line-height: 16px;
  margin: 6px;
  padding: 12px 18px;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;
}
