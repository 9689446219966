.root {
  fill: var(--colorGrey100);
  margin: 0 3px;
  overflow: visible !important;
}

.filled {
  fill: var(--colorSecondary);
  margin: 0 3px;
  overflow: visible !important;
}
