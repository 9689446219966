@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  composes: textSmall from global;

  color: var(--colorLightBeige);
  font-size: 18px;
  padding-bottom: 18px;
  padding-top: 18px;
  height: 100%;
  margin-top: auto;

  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
  }

  @media (--viewportLarge) {
    font-size: 30px;
    line-height: 30px;
    font-weight: 400;

    height: unset;
    width: var(--TabNav_linkWidth);
    border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 16px;

    padding-top: 5px; /* Drop the text to adjust it to correct baseline */
    padding-bottom: 3px;
  }
}

.icon {
  display: none;

  @media (--viewportLarge) {
    display: block;
    fill: var(--colorGreenDark);
    height: 91px;
    width: 84px;
  }
}

.selectedLink {
  border-bottom-color: var(--colorBlack);
  color: var(--colorWhite);
  font-weight: var(--fontWeightSemibold);

  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
  }
}

.disabled {
  pointer-events: none;
  color: var(--colorGreenDark);
  text-decoration: none;
}
