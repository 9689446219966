.root {
}

.input {
}
.textarea {
}

.inputSuccess {
}
.inputError {
  border: var(--borderErrorField);
}

.inlineLabel {
  display: inline-block;
}

.optional {
  color: var(--colorDarkBeigeText);
  display: inline-block;
  margin-left: 12px;
}

.dateTimeInput {
  display: flex;
  align-items: center;
}

.dateTimeInput input {
  width: 100%;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 5px;
  padding: 5px;
}

/* .numberInput button {
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.numberInput button:disabled {
  cursor: not-allowed;
} */

/* .buttonIconWrapper {
  align-items: center;
  background: var(--colorLightBeige);
  border-radius: 50%;
  display: inline-flex;
  height: 30px;
  justify-content: center;
  width: 30px;

  &:hover {
    background: var(--colorDarkBeige);
  }
}

.buttonIcon {
  fill: none;
  height: 16px;
  stroke: var(--colorForeground);
  width: 16px;
} */

/* Disabled state
button:disabled .buttonIconWrapper {
  background: var(--colorLightBeige);
  cursor: not-allowed;
} */
