@import '../../styles/customMediaQueries.css';

.root {
  /* Font */
  composes: p from global;
  color: var(--colorWhite) !important;
  text-align: center;
  line-height: 1; /* Ensure there's no extra vertical space */

  background-color: var(--colorFail);

  /* Centering the text inside the dot */
  display: inline-flex; /* Use inline-flex to keep the span inline while centering content */
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */

  /* Layout of a red dot */
  width: 12px; /* Set width equal to height for a circular shape */
  height: 12px;
  border-radius: 50%; /* Use 50% to make sure it's perfectly circular */
  margin-left: 4px;
  padding: 0; /* Remove padding to maintain circle shape */

  /* Font margins off */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    width: 12px; /* Adjust width and height for medium viewports */
    height: 12px;
  }

  @media (--viewportLarge) {
    font-size: 14px;
    width: 12px; /* Adjust width and height for large viewports */
    height: 12px;
    border-radius: 50%;
    top: -2px;
    position: relative;
  }
}
