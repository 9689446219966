.root {
  left: -16px;
  position: relative;
  width: 100%;
  height: 100%;
}

.track {
  /* Position */
  position: absolute;
  top: 50%;
  margin-top: 10px;

  /* Layout */
  width: calc(100% + 12px);
  height: var(--RangeSlider_offset);
  padding: 0 10px;
  border-radius: var(--borderRadiusLarge);

  box-sizing: content-box;
  background-color: var(--colorGrey100);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.range {
  position: absolute;
  top: 50%;
  margin-top: 10px;
  margin-left: 0px;
  min-width: var(--RangeSlider_offset);
  height: var(--RangeSlider_offset);
  background: linear-gradient(90deg, var(--colorSecondary) 0%, var(--marketplaceColor) 100%);
  border-radius: var(--borderRadiusLarge);
}
