@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

/* Container for side and content */
.container {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  width: 100%;

  padding: 6px;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.main {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  padding: 24px;

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 48px 36px 82px 59px;
    background-color: var(--colorWhite);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 48px calc((100% - 1056px) / 2) 82px 59px;
  }
}

.sideNav {
  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusLarge);
  border-top: 1px solid var(--colorGrey100);
  box-shadow: var(--boxShadowLight);
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  overflow-x: scroll;
  padding: 0;

  @media (--viewportLarge) {
    border-top: none;
    box-shadow: none;
    flex-direction: column;
    margin-right: 6px;
    overflow-x: auto;
    padding: 60px 0 40px 72px;
    position: relative;
    width: 30%;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;
  padding: 2px 12px 0;

  @media (--viewportMedium) {
    min-height: 48px;
    padding: 0 12px 0;
  }

  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    margin-top: 24px;
    padding: 0;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}
