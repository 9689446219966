@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  align-items: center;
  justify-content: center;
  margin-left: 36px;

  &:first-child {
    margin-left: 0;
  }
}

.icon {
  align-items: center;
  background: var(--colorWhite);
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0 6px 0 6px;
  width: 40px;

  @media (--viewportLarge) {
    margin: 0 12px 0 6px;
  }

  /* &:hover {
  } */
}

.icon svg {
  fill: none;
  height: 16px;
  stroke: var(--colorBlack);
  width: 16px;
}

a.tabContent {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;

  /* Font */
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 16px;

  color: var(--colorForeground);
  padding-bottom: 10px;

  padding: 6px;

  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    color: var(--colorBlack);

    .icon {
      background-color: var(--colorSuperLightBeige);
    }
  }
}

.tabContent h2 {
  padding-bottom: 12px;
}

.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}

.selectedTabContent .icon {
  background: var(--colorBlack);
}

.selectedTabContent svg {
  stroke: var(--colorWhite);
}

a.selectedTabContent {
  color: var(--colorBlack);
  font-weight: var(--fontWeightMedium);

  &:hover {
    .icon {
      background: var(--colorSuperLightBeige);
    }

    .icon svg {
      stroke: var(--colorBlack);
    }
  }
}

a.selectedTabContent h1 {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  border-bottom-color: var(--colorBlack);
  padding-bottom: 8px;
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}

/* Dark skin
.tabContentDarkSkin {
  color: var(--colorGrey300);

  &:hover {
    color: var(--colorWhite);
  }
} */

/* .selectedTabContentDarkSkin {
  border-bottom-color: var(--colorWhite);
  color: var(--colorWhite);
} */

/* .disabledDarkSkin {
  color: var(--colorBlack);
  opacity: 0.3;
} */
