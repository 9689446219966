/* reset */
button,
h3 {
  all: unset;
}

.AccordionRoot {
  /* border-radius: 6px; */
  /* width: 300px; */
  /* background-color: var(--mauve-6); */
  box-shadow: 0 2px 10px var(--black-a4);
  /* margin-right: 100px; */
}

.AccordionItem {
  overflow: hidden;
  margin-top: 1px;
  border-bottom: 1px solid;
  border-color: var(--colorGrey300);
}

.AccordionItem:first-child {
  margin-top: 0;
  /* border-top-left-radius: 4px; */
  /* border-top-right-radius: 4px; */
}

.AccordionItem:last-child {
  /* border-bottom-left-radius: 4px; */
  /* border-bottom-right-radius: 4px; */
}

.AccordionItem:focus-within {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 0 2px var(--mauve-12);
}

.AccordionHeader {
  display: flex;
}

.AccordionTrigger {
  font-family: inherit;
  background-color: transparent;
  /* padding: 0 20px; */
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
  color: var(--violet-11);
  /* box-shadow: 0 1px 0 var(--colorGrey200); */
  /* background-color: white; */
}

.AccordionTrigger:hover {
  background-color: var(--mauve-2);
}

.AccordionContent {
  overflow: hidden;
  font-size: 15px;

  /* color: var(--mauve-11); */
  /* background-color: var(--mauve-2); */
}
.AccordionContent[data-state='open'] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionContent[data-state='closed'] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionContentText {
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 26px;
  padding: 15px 0px;
}

.AccordionChevron {
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  fill: none;
}
.AccordionTrigger[data-state='open'] > .AccordionChevron {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
