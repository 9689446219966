@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
}

.reviewItem {
  &:last-of-type {
    margin-bottom: 0;
  }
}

.review {
  margin: 6px 12px;

  @media (--viewportLarge) {
    margin: 0 12px;
  }
}

.avatar {
  height: 48px;
  margin-right: 24px;
  width: 48px;
}

.reviewContent {
  border: 2px solid var(--colorLightBeige);
  border-radius: 1rem 1rem 0 0;
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 26px;
  margin: 0;
  padding: 24px 18px 18px;
  white-space: pre-line;
}

.reviewInfo {
  background: var(--colorLightBeige);
  border-radius: 0 0 1rem 1rem;
  color: var(--colorForeground);
  display: flex;
  margin-bottom: 0px;
  padding: 18px;
}

.author {
  font-weight: var(--fontWeightMedium);
}

.date {
  font-weight: var(--fontWeightRegular);
}

.separator {
  margin-right: 6px;
}

.reviewRating {
  align-items: center;
  display: flex;
  margin-top: 3px;
  margin-bottom: 9px;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}
