@import '../../styles/customMediaQueries.css';

.root {
  background-color: var(--colorLightBeige);
  display: none;

  align-items: center;
  display: flex;
  /* height: 57px; */
  justify-content: center;
  padding: 6px 0;

  @media (--viewportLarge) {
    padding: 18px 0;
  }
}

.tab {
  align-items: center;
  display: flex;
  height: 100%;

  &:first-child {
    margin-left: 0;
  }
}
