.root {
}

.input {
}
.textarea {
}

.inputSuccess {
}
.inputError {
  border: var(--borderErrorField);
}

.inlineLabel {
  display: inline-block;
}

.optional {
  color: var(--colorDarkBeigeText);
  display: inline-block;
  margin-left: 12px;
}
